export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0];

export const dictionary = {
		"/(front-page)": [~5,[2]],
		"/(main)/about": [6,[3]],
		"/(main)/admin": [~7,[3,4]],
		"/(main)/admin/db": [~8,[3,4]],
		"/(main)/admin/pleiades-places": [~9,[3,4]],
		"/(main)/admin/users": [~10,[3,4]],
		"/(main)/admin/users/edit": [~11,[3,4]],
		"/(main)/beta-login": [12,[3]],
		"/(main)/documentation": [13,[3]],
		"/(main)/edit/route-segments/upload": [14,[3]],
		"/(main)/login": [~15,[3]],
		"/(main)/me": [16,[3]],
		"/(main)/me/change-password": [17,[3]],
		"/(main)/me/edit": [~18,[3]],
		"/(main)/tutorials": [19,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';